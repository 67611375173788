<template>
  <div class="internation_realname">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="search">
      <div class="search_input">
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 210px; border-right: 1px #dcdfe6 solid"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入手机号查询"
          v-model="searchPhone"
          style="width: 300px"
        ></el-input>
        <i
          class="icon-sousuo iconfont"
          @click="searchList"
          style="cursor: pointer"
        ></i>
      </div>
    </div>

    <el-card class="box-card" v-for="(item, i) in realNameList" :key="i">
      <el-form label-width="200px">
        <div style="display: flex">
          <el-form-item label="手机号："
            >{{ item.phoneArea }} {{ item.phone }}</el-form-item
          >
          <el-form-item label="用户提交时间：">{{
            item.submitTime
          }}</el-form-item>
        </div>
        <el-form-item label="证件正面照">
          <el-image
            style="width: 400px; height: 214px"
            :src="item.frontImg"
            :preview-src-list="[item.frontImg]"
          >
          </el-image>
          <div style="display: flex; flex-direction: column">
            <div>
              <el-button
                type="success"
                plain
                @click="handlePass('froutImg')"
                :disabled="froutStatus != 1 || froutStatus === 2 ? true : false"
                >审核通过</el-button
              >
              <el-button
                type="danger"
                plain
                @click="handleRefuse('froutImg')"
                :disabled="froutStatus != 1 || froutStatus === 3 ? true : false"
                >审核拒绝</el-button
              >

              <el-button type="text" disabled v-if="froutStatus === 2"
                >已通过 <i class="el-icon-check"></i
              ></el-button>
              <el-button type="text" disabled v-if="froutStatus === 3">
                已拒绝<i class="el-icon-close"></i>
              </el-button>
            </div>

            <p class="form_txt" v-if="froutStatus === 3">
              拒绝原因：{{ reasonValueFrout }}
            </p>
          </div>
        </el-form-item>

        <el-form-item label="证件反面照">
          <el-image
            style="width: 400px; height: 214px"
            :src="item.backImg"
            :preview-src-list="[item.backImg]"
          >
          </el-image>
          <div style="display: flex; flex-direction: column">
            <div>
              <el-button
                type="success"
                plain
                @click="handlePass('backImg')"
                :disabled="backStatus != 1 || backStatus === 2 ? true : false"
                >审核通过</el-button
              >
              <el-button
                type="danger"
                plain
                @click="handleRefuse('backImg')"
                :disabled="backStatus != 1 || backStatus === 3 ? true : false"
                >审核拒绝</el-button
              >
              <el-button type="text" disabled v-if="backStatus === 2"
                >已通过 <i class="el-icon-check"></i
              ></el-button>
              <el-button type="text" disabled v-if="backStatus === 3">
                已拒绝<i class="el-icon-close"></i>
              </el-button>
            </div>

            <p class="form_txt" v-if="backStatus === 3">
              (拒绝原因：{{ reasonValueBack }})
            </p>
          </div>
        </el-form-item>

        <el-form-item label="手持证件照">
          <el-image
            style="width: 400px; height: 214px"
            :src="item.holdImg"
            :preview-src-list="[item.holdImg]"
          >
          </el-image>
          <div>
            <div>
              <el-button
                type="success"
                plain
                @click="handlePass('holdImg')"
                :disabled="holdStatus != 1 || holdStatus === 2 ? true : false"
                >审核通过</el-button
              >
              <el-button
                type="danger"
                plain
                @click="handleRefuse('holdImg')"
                :disabled="holdStatus != 1 || holdStatus === 3 ? true : false"
                >审核拒绝</el-button
              >
              <el-button type="text" disabled v-if="holdStatus === 2"
                >已通过 <i class="el-icon-check"></i
              ></el-button>
              <el-button type="text" disabled v-if="holdStatus === 3">
                已拒绝<i class="el-icon-close"></i>
              </el-button>
            </div>

            <p class="form_txt" v-if="holdStatus === 3">
              拒绝原因：{{ reasonValueHold }}
            </p>
          </div>
        </el-form-item>
      </el-form>

      <div class="inter_btn">
        <el-button type="primary" plain round @click="handleReset"
          >重置审核</el-button
        >
        <el-button
          v-if="froutStatus !== 3 && backStatus !== 3 && holdStatus !== 3"
          type="success"
          plain
          round
          @click="handleSubmitPass"
          :disabled="
            froutStatus == 2 && backStatus == 2 && holdStatus == 2
              ? false
              : true
          "
          >审核通过，并补全信息</el-button
        >
        <el-button
          v-else-if="froutStatus == 3 || backStatus == 3 || holdStatus == 3"
          type="danger"
          plain
          round
          @click="handleSubmitRefuse(item.id)"
          :disabled="
            froutStatus !== 1 && backStatus !== 1 && holdStatus !== 1
              ? false
              : true
          "
          >审核拒绝，审核下一位</el-button
        >

        <el-dialog title="实名认证信息补全" :visible.sync="dialogFormVisible">
          <el-form :model="form">
            <el-form-item label="姓名" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="证件号码" :label-width="formLabelWidth">
              <el-input v-model="form.code" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" :label-width="formLabelWidth">
              <el-date-picker
                v-model="form.dateBirth"
                type="date"
                placeholder="选择出生日期"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button round @click="dialogFormVisible = false"
              >取 消</el-button
            >
            <el-button
              type="primary"
              round
              @click="handleInputInfo(item.id, item.userId)"
              :disabled="
                form.name && form.code && form.dateBirth ? false : true
              "
              >提交并审核下一位</el-button
            >
          </div>
        </el-dialog>
      </div>
    </el-card>

    <Pagination
      v-show="pageLength >= 0"
      :total="pageLength"
      :limit.sync="pageSize"
      @pagination="handlePageChange"
      :page-sizes="pageSizes"
      layout="total, prev, pager, next"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrlInternation } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      searchPhone: "",

      valueArea: "",
      options: [],
      BreadcrumbCon: [
        {
          name: "实名认证",
        },
        {
          name: "待审核列表",
        },
      ], // 面包屑数据

      realNameList: [],
      froutStatus: 1,
      backStatus: 1,
      holdStatus: 1,

      reasonValueFrout: "",
      reasonValueBack: "",
      reasonValueHold: "",

      dialogFormVisible: false,
      form: {
        name: "",
        code: "",
        dateBirth: "",
      },
      formLabelWidth: "120px",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100],
    };
  },

  mounted() {
    this.options = areaCodeData;

    showLoading();
    const opt = {
      url: reqUrlInternation.getAuditingList,
      method: "POST",
      params: JSON.stringify({
        phone: "",
        phoneArea: "",
        auditStatus: 1,
        pageSize: 1,
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code == 0) {
          this.realNameList = res.data.data.list;
          res.data.data.list.map((item) => this.changeItem(item));
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          hideLoading();
        }
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrlInternation.getAuditingList,
        method: "POST",
        params: JSON.stringify({
          phone: this.searchPhone,
          phoneArea: this.valueArea,
          auditStatus: 1,
          pageSize: 1,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.realNameList = res.data.data.list;
            res.data.data.list.map((item) => this.changeItem(item));
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;

            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePass(type) {
      this.$confirm("请确认是否进行“审核通过”操作？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == "froutImg") {
            this.froutStatus = 2;
          } else if (type == "backImg") {
            this.backStatus = 2;
          } else if (type == "holdImg") {
            this.holdStatus = 2;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    handleRefuse(type) {
      if (type == "froutImg") {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            this.reasonValueFrout = value;
            this.froutStatus = 3;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      } else if (type == "backImg") {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            this.reasonValueBack = value;
            this.backStatus = 3;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      } else if (type == "holdImg") {
        this.$prompt("请输入拒绝原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputErrorMessage: "拒绝原因不得为空",
          inputValidator: (value) => {
            if (!value) {
              return "拒绝原因不得为空";
            }
          },
        })
          .then(({ value }) => {
            this.reasonValueHold = value;
            this.holdStatus = 3;
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },

    handleSubmitPass() {
      this.dialogFormVisible = true;
    },

    // 通过后补全信息
    handleInputInfo(id, userId) {
      this.$confirm("请确认是否进行“审核通过”操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const optBirth = {
            url: reqUrlInternation.alertAuthMsg,
            method: "POST",
            params: JSON.stringify({
              id: id,
              userId: userId,
              authName: this.form.name,
              idCard: this.form.code,
              birthday: this.form.dateBirth,
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "信息补全成功",
                  type: "success",
                });
                this.refresh();
                hideLoading();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(optBirth);

          showLoading();
          const opt = {
            url: reqUrlInternation.auditOverSeaUser,
            method: "POST",
            params: JSON.stringify({
              id: id,
              frontStatus: this.froutStatus,
              backStatus: this.backStatus,
              holdStatus: this.holdStatus,
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "审核通过成功",
                  type: "success",
                });
                hideLoading();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    handleSubmitRefuse(id) {
      this.$confirm("请确认是否进行“审核拒绝”操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrlInternation.auditOverSeaUser,
            method: "POST",
            params: JSON.stringify({
              id: id,
              frontStatus: this.froutStatus,
              frontReason: this.reasonValueFrout,
              backStatus: this.backStatus,
              backReason: this.reasonValueBack,
              holdStatus: this.holdStatus,
              holdReason: this.reasonValueHold,
            }),
            resFunc: (res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: "审核拒绝成功",
                  type: "success",
                });
                this.refresh();

                hideLoading();
              }
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    handleReset() {
      this.froutStatus = 1;
      this.backStatus = 1;
      this.holdStatus = 1;
    },

    changeItem(item) {
      item.submitTime = format_time_date(item.submitTime);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrlInternation.getAuditingList,
        method: "POST",
        params: JSON.stringify({
          phone: this.searchPhone,
          phoneArea: this.valueArea,
          auditStatus: 1,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.realNameList = res.data.data.list;
            res.data.data.list.map((item) => this.changeItem(item));
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;

            hideLoading();
          }
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.internation_realname {
  position: relative;
  height: calc(100vh - 42px);
  overflow-y: scroll;

  .search {
    display: flex;

    .search_input {
      border: 1px solid #dcdfe6;
      display: flex;
      border-radius: 30px;
      background: #fff;
      line-height: 40px;
      padding-right: 20px;

      ::v-deep .el-input {
        .el-input__inner {
          border: none;
          border-radius: 30px;
        }
      }
    }
  }

  .el-card {
    margin-top: 20px;
    border-radius: 20px;
  }

  ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;

    .el-image {
      margin-right: 40px;
    }

    .form_txt {
      width: 310px;
      white-space: normal;
      color: #c0c4cc;
    }
  }

  .inter_btn {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
